<template>
  <div>
    <v-card-title v-if="!hideCardTitle">
      <span>{{ timeSheet.title }}</span>
      <v-spacer></v-spacer>
      <time-sheet-status-chip :status="timeSheet.status" small>
      </time-sheet-status-chip>
    </v-card-title>
    <slot name="header"> </slot>
    <v-divider></v-divider>

    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr v-if="hideCardTitle">
            <td class="font-weight-medium">Bezeichnung</td>
            <td>
              <v-chip
                v-if="!timeSheet.title"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.title }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Typ</td>
            <td>
              <v-chip
                v-if="!timeSheet.type"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                <time-sheet-type-chip :type="timeSheet.type" small>
                </time-sheet-type-chip>
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Meldung für</td>
            <td>
              <v-chip outlined small class="my-1"
                ><v-icon small left>mdi-account-circle</v-icon
                >{{ timeSheet.user.displayName }}</v-chip
              >
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Startzeitpunkt</td>
            <td>
              <v-chip
                v-if="!timeSheet.workedDuration.start.timestamp"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{
                  timestampConverter(timeSheet.workedDuration.start.timestamp)
                }}
                Uhr
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Endzeitpunkt</td>
            <td>
              <v-chip
                v-if="!timeSheet.workedDuration.end.timestamp"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timestampConverter(timeSheet.workedDuration.end.timestamp) }}
                Uhr
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Startzeitpunkt (Abrechenbar)</td>
            <td>
              <v-chip
                v-if="!timeSheet.billableDuration.start.timestamp"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{
                  timestampConverter(timeSheet.billableDuration.start.timestamp)
                }}
                Uhr
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Endzeitpunkt (Abrechenbar)</td>
            <td>
              <v-chip
                v-if="!timeSheet.billableDuration.end.timestamp"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{
                  timestampConverter(timeSheet.billableDuration.end.timestamp)
                }}
                Uhr
              </span>
            </td>
          </tr>
          <tr>
            <td class="font-weight-medium">Abrechnungsart</td>
            <td>
              <v-chip
                v-if="!timeSheet.payrollType.id"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.payrollType.title }}
              </span>
            </td>
          </tr>
          <!-- dutyTime -->
          <tr v-if="timeSheet.type === 'dutyTime'">
            <td class="font-weight-medium">Ort</td>
            <td>
              <v-chip
                v-if="!timeSheet.additionalData.location.name"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.additionalData.location.name }}
              </span>
            </td>
          </tr>
          <tr v-if="timeSheet.type === 'dutyTime'">
            <td class="font-weight-medium">Einsatzmittel/Funktion</td>
            <td>
              <v-chip
                v-if="
                  !timeSheet.additionalData.assignedPosition.unit.title ||
                  !timeSheet.additionalData.assignedPosition.position.shortTitle
                "
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.additionalData.assignedPosition.unit.title }} –
                {{
                  timeSheet.additionalData.assignedPosition.position.shortTitle
                }}
              </span>
            </td>
          </tr>

          <!-- courseTime -->
          <tr v-if="timeSheet.type === 'courseTime'">
            <td class="font-weight-medium">Lehrgang</td>
            <td>
              <v-chip
                v-if="!timeSheet.additionalData.course"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.additionalData.course.title }}
              </span>
            </td>
          </tr>
          <tr v-if="timeSheet.type === 'courseTime'">
            <td class="font-weight-medium">Externe Bezeichnung</td>
            <td>
              <v-chip
                v-if="!timeSheet.additionalData.externalName"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.additionalData.externalName }}
              </span>
            </td>
          </tr>
          <tr v-if="timeSheet.type === 'courseTime'">
            <td class="font-weight-medium">Kurs-/Lehrgangsnummer</td>
            <td>
              <v-chip
                v-if="!timeSheet.additionalData.externalId"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.additionalData.externalId }}
              </span>
            </td>
          </tr>
          <tr v-if="timeSheet.type === 'courseTime'">
            <td class="font-weight-medium">Nachweisdatum</td>
            <td>
              <v-chip
                v-if="!timeSheet.additionalData.proofDate"
                label
                small
                color="error"
                class="my-1"
                ><v-icon small left>mdi-alert-octagon</v-icon>Nicht
                festgelegt</v-chip
              >
              <span v-else>
                {{ timeSheet.additionalData.proofDate }}
              </span>
            </td>
          </tr>

          <tr v-if="timeSheet.additionalData.files.length">
            <td class="font-weight-medium">Anhänge</td>
            <td>
              <v-chip
                v-for="(file, index) in timeSheet.additionalData.files"
                :key="index"
                outlined
                label
                small
                class="ma-1 font-weight-medium"
                @click="openFile(file.path)"
                ><v-icon small left>{{ getFileIcon(file.type) }}</v-icon
                >{{ file.name }}</v-chip
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { storage } from "@/firebase";
import TimeSheetTypeChip from "@/components/accounting/time-sheets/TimeSheetTypeChip.vue";
import TimeSheetStatusChip from "@/components/accounting/time-sheets/TimeSheetStatusChip.vue";
export default {
  name: "time-sheet-details-card-content",
  props: {
    timeSheet: {
      type: Object,
    },
    hideCardTitle: {
      type: Boolean,
      default: false,
      required: false,
    },
    hideAssignedInfo: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: { TimeSheetTypeChip, TimeSheetStatusChip },
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    openFile(filePath) {
      const storageRef = storage.ref();
      // Create a reference to the file
      const fileRef = storageRef.child(filePath);

      // Get the download URL
      fileRef
        .getDownloadURL()
        .then((url) => {
          // Open the file in a new tab
          window.open(url, "_blank");
        })
        .catch((error) => {
          console.error("Error getting download URL:", error);
        });
    },
    getFileIcon(fileType) {
      let [generalType, specificType] = fileType.split("/");
      switch (generalType) {
        case "application":
          switch (specificType) {
            case "pdf":
              return "mdi-file-pdf-box";
            case "msword":
            case "vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "mdi-file-word";
            case "vnd.ms-excel":
            case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "mdi-file-excel";
            case "vnd.ms-powerpoint":
            case "vnd.openxmlformats-officedocument.presentationml.presentation":
              return "mdi-file-powerpoint";
            case "zip":
            case "x-rar-compressed":
            case "x-7z-compressed":
              return "mdi-folder-zip";
            case "plain":
              return "mdi-file-document";
            default:
              return "mdi-file";
          }
        case "image":
          return "mdi-file-image";
        case "audio":
          return "mdi-file-music";
        case "video":
          return "mdi-file-video";
        default:
          return "mdi-file";
      }
    },
  },
  computed: {},
};
</script>
